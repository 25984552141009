import * as React from 'react'
import "./select.scss"

const NestedSelect = (props) => {
    const getIsSelected = (option) => {
        if (option.selected) {
            return true
        } else {
            return false
        }
    }
    const getChildren = (option, level = 0) => {
        let spaces = new Array(level+1).join('\xA0\xA0\xA0')
        return option.children.map(child => (
            <>
                {child.children && child.children.length > 0 ? (
                    <><option value={child.value} disabled={true}>{spaces}{child.title}</option>{getChildren(child, ++level)}</>
                ):(
                    <option value={child.value} selected={getIsSelected(child)}>{spaces}{child.title}</option>
                )}
            </>
        ))
    }

    const getOptions = (options, hasGroups = true) => {
        if (hasGroups) {
            return options.map(option => (
                // Get top level options as optgroups
                <optgroup label={option.title}>{getChildren(option)}</optgroup>
            ))
        } else {
            return options.map(option => (
                <>
                    {option.children && option.children.length > 0 ? (
                        <><option value={option.value} disabled={true}>{option.title}</option>{getChildren(option, 1)}</>
                    ):(
                        <option value={option.value} selected={getIsSelected(option)}>{option.title}</option>
                    )}
                </>
            ))
        }
    }

    const getClasses = (classes) => {
        return "select " + classes
    }

    return (
        <div className={getClasses(props.classes)}>
            <select onChange={props.changeHandler} value={props.active}>
                {getOptions(props.options, props.hasGroups)}
            </select>
            <span className="focus"></span>
        </div>
    )
}

export default NestedSelect