import React, {useEffect} from 'react';
import {useStaticQuery, graphql, navigate} from 'gatsby'
import NestedSelect from "../global/nestedSelect";
import "./educationNavigation.scss"

const EducationNavigation = (props) => {
    const data = useStaticQuery(graphql`
        {
          craft {
            menu: navigationNodes(navHandle: "educationSectionNavigation", level: 1, status: null) {
              title
              nodeUri
              enabled
              children {
                id
                title
                nodeUri
                value: nodeUri
                type
                newWindow
                children {
                  id
                  title
                  nodeUri
                  value: nodeUri
                  type
                  newWindow
                }
              }
            }
          }
        }
    `)

    useEffect(() => {
        let activeMenuItem = document.querySelector('.education-navigation a.active')
        if (activeMenuItem) {
            menuSelectedWalker(activeMenuItem)
        }
    })

    const menuSelectedWalker = (node) => {
        let ul = node.closest('ul')
        let parent = (ul) ? ul.parentNode : false

        if (parent) {
            Array.from(node.closest('ul').parentNode.children).some(element => {
                if (element.className.split(" ").indexOf("nav-parent") > -1) {
                    element.classList.add('active')
                    menuSelectedWalker(element)
                    return true
                }
                return null
            })
        }
    }

    const getUrl = (node) => {
        // Test if empty
        if (node.nodeUri) {
            // Test if absolute URL
            var r = new RegExp('^(?:[a-z]+:)?//', 'i');
            if (r.test(node.nodeUri)) {
                return node.nodeUri
            } else {
                return `/${node.nodeUri}`
            }
        } else {
            return '#'
        }
    }

    const getLink = (node) => {
        if (node.nodeUri === props.active) {
            return (
                <a className="active">{node.title}</a>
            )
        } else {
            if (node.nodeUri === '') {
                return (
                    <a onClick={toggleSubMenu} className="nav-parent" role="button">{node.title}</a>
                )
            } else {
                return (
                    <a href={getUrl(node)} target={node.newWindow === '1' ? '_blank' : '_self'} rel="noreferrer">{node.title}</a>
                )
            }
        }
    }

    const toggleSubMenu = (event) => {
        event.preventDefault();
        event.target.classList.toggle("active")
    }

    const navWalker = (nodes, child = false) => {
        if (!nodes || nodes.length === 0) {
            return null
        }

        if (child) {
            return nodes.map(node => {
               if (node.children && node.children.length > 0) {
                   return <li key={node.id}>
                           {getLink(node)}
                           <ul>{ navWalker(node.children, true) }</ul>
                       </li>
               } else {
                   return <li key={node.id}>{getLink(node)}</li>
               }
            })
        } else {
            return nodes.map(node => (
                <div className="desktop">
                    {node.nodeUri === "" && node.enabled === true ? (
                        <a onClick={toggleSubMenu} className="nav-parent" role="button">{node.title}</a>
                    ):(
                        null
                    )}
                    <ul>{ navWalker(node.children, true) }</ul>
                </div>
            ))
        }
    }

    const changeNavigationItem = (event) => {
        navigate(`/${event.target.value}`)
    }

    return (
        <div className="education-navigation">
            <NestedSelect changeHandler={changeNavigationItem} classes="mobile" options={data.craft.menu} active={props.active}></NestedSelect>
            {navWalker(data.craft.menu)}
        </div>
    )
}

export default EducationNavigation