import * as React from "react"
import {graphql} from "gatsby";
import HumaneHeroesNavigation from "../../components/education/humaneHeroesNavigation";
import ImageLink from "../../components/global/imageLink";
import InnerLayout from "../../components/global/innerLayout"
import PageTitle from "../../components/global/pageTitle";
import parse from "html-react-parser";
import defaultFrontCover from "../../images/coming-soon-front-cover.png"
import "./humane-heroes-basic.scss"

export const query = graphql`
    query($id: [Craft_QueryArgument]) {
      craft {
        page: entry(section: "humaneHeroesBasicPage", id: $id) {
          ... on Craft_humaneHeroesBasicPage_default_Entry {
          seomatic {
              metaTitleContainer
              metaTagContainer
              metaLinkContainer
              metaScriptContainer
              metaJsonLdContainer
              metaSiteVarsContainer
              frontendTemplateContainer
            }
            id
            uri
            title
            contentExtendedButtonsImgOptionsEducation1
          }
        }
        seriesBooks: entries(section: "humaneHeroesBookPage", orderBy: "title") {
          ... on Craft_humaneHeroesBookPage_default_Entry {
            key: id
            title
            uri
            educationBookFrontCover {
              url(transform: "bookCoverSmallSide")
              ... on Craft_websiteEducationGeneral_Asset {
                altText
              }
            }
          }
        }
      }
    }
  `

const HumaneHeroesBasicPage = ({ data, pageContext }) => {

    const getBookFrontCover = (node) => {
        if (node.educationBookFrontCover.length > 0) {
            return node.educationBookFrontCover[0].url
        } else {
            return defaultFrontCover
        }
    }

    const getBookFrontCoverAltText = (node) => {
        if (node.educationBookFrontCover.length > 0) {
            return node.educationBookFrontCover[0].altText
        } else {
            return null
        }
    }

    const getSeriesBooks = () => {
        return data.craft.seriesBooks.map(book => (
            <div className="browse-post">
                <ImageLink src={getBookFrontCover(book)} alt={getBookFrontCoverAltText(book)} to={`/${book.uri}`} openInNewTab={false}/>
                <a href={`/${book.uri}`}>{book.title}</a>
            </div>
        ))
    }
    return (
        <InnerLayout seomatic={data.craft.page.seomatic} entryId={data.craft.page.id}>
            <div className="midd humane-heroes-basic-page">

                <PageTitle title={data.craft.page.title} className="humane-heroes"/>

                <div className="content-page">
                    <div className="container-small">

                        <div className="content-colum-row padding-top-none">
                            <div className="sidebar">
                                <HumaneHeroesNavigation active={data.craft.page.uri}/>
                                <hr/>
                                <div className="browse-book">
                                    <h4>Browse the Series</h4>
                                    {getSeriesBooks()}
                                </div>
                            </div>
                            <div className="content-right">

                                {parse(data.craft.page.contentExtendedButtonsImgOptionsEducation1)}

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </InnerLayout>
    )
}

export default HumaneHeroesBasicPage
